import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import {addDiscountMargin, addServicePrices} from '../../../../Redux/actions/priceAction'

import Checkbox from '../../../general/Checkbox'


import SettingHeader from "../SettingHeader";
import {CheckboxDate} from "../../../../data/checkboxData";

import ServiceGroupTable from "./ServiceGroupTable";
import ServiceTable from "./ServiceTable";
import {changeDictServiceState} from "../../../../Redux/actions/dicrServiceActions";
import {addGroupService} from "../../../../Redux/actions/dictServiceGroupActions";

const SettingServicePrices = (props) => {

    useEffect(() => {
        props.addGroupService()
        props.addDiscountMargin()
        props.addServicePrices()
        return () => {
            props.changeDictServiceState({selected_category: null})
        }
    }, [])


    return (
        <div className='setting-content'>

            <SettingHeader header='Перечень работ и услуг'/>

            <div className='box'>
                <div className='box__forms'>

                    <p>Прейскурант работ и услуг выполняющихся в вашей компании с указанием цены за работу/услугу</p>
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Показать удаленые'
                        onChange={event => props.changeDictServiceState({showDeleted: event.target.checked})}
                        checked={props.showDeleted}
                        invisible={!props.permissions.includes('setting_see_deleted_service')}
                    />

                    <div className='box__row'>

                        <ServiceGroupTable/>
                        <ServiceTable/>

                    </div>

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    permissions: state.data.user.role.permissions,
    showDeleted: state.dictService.showDeleted
})

const mapDispatchToProps = {
    addGroupService,
    addDiscountMargin,
    addServicePrices,
    changeDictServiceState
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingServicePrices)