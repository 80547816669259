import {Table} from '../../data/tableHeaders'
import {Action} from "../../data/ActionTypes";

const current_key = 'dict_service_'

const initialState = {

   services: [],
   serviceGroups: [],

   edit: 0,
   group_edit: 0,

   group_title: '',
   group_deleted: false,
   
   title: '',
   price: 0,
   cost: 0,
   warranty: 0,
   warranty_value: 30*24*60*60,
   code: '',
   earnings_percent: 0,
   earnings_summ: 0,
   deleted: false,
   category: {},

   showDeleted: false,

   selected_category: {},

   table_headers: JSON.parse(localStorage.getItem(current_key + 'table_headers')) || Table.Fields.Service
}
 
export const dictServiceReducer = (state = initialState, action) => {
   switch (action.type){

      case Action.DictService.CHANGE_STATE: {
         const local_save = ['table_headers']
         Object.keys(action.data).forEach(field => {
            if (local_save.includes(field)) localStorage.setItem(current_key + field, JSON.stringify(action.data[field]))
         })
         return {...Object.assign(state, action.data)}
      }
 
      case Action.DictService.EDIT_GROUP: {
         return {
            ...state, 
            group_edit: action.group.id,
            group_title: action.group.title,
            group_deleted: action.group.deleted,
         }
      }

      case Action.DictService.EDIT: {
         return {
            ...state, 
            edit: action.service.id,
            title: action.service.title,
            price: action.service.price,
            cost: action.service.cost,
            warranty: action.service.warranty,
            code: action.service.code,
            earnings_percent: action.service.earnings_percent,
            earnings_summ: action.service.earnings_summ,
            deleted: action.service.deleted,
            category: action.service.category
         }
      }
 
      case Action.DictService.RESET_GROUP: {
         return {
            ...state, 
            group_edit: 0,

            group_title: '',
            group_deleted: false
         }
      }

      case Action.DictService.RESET: {
         return {
            ...state, 
            edit: 0,

            title: '',
            price: 0,
            cost: 0,
            warranty: 0,
            warranty_value: 30*24*60*60,
            code: '',
            earnings_percent: 0,
            earnings_summ: 0,
            deleted: false,
            category: {},
         }
      }
       
      default: return state
   }
    
}