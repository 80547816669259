export const Action = Object.freeze({
    Part: {
        CHANGE_STATE: 'PART/CHANGE_STATE',
        EDIT: 'PART/EDIT',
        RESET: 'PART/RESET',
        SELECT: 'PART/SELECTED',
        CHANGE_PROPERTY: 'PART/CHANGE_PROPERTY',
        ADD_PROPERTY: 'PART/ADD_PROPERTY',
        DELETE_PROPERTY: 'PART/DELETE_PROPERTY'
    },
    Residue: {
        EDIT: 'RESIDUE/EDIT_RULE',
        RESET: 'RESIDUE/RESET_RULE',
    },
    MainData: {
        CHANGE_STATE: 'MAIN_DATA/CHANGE_STATE',
        EDIT: 'MAIN_DATA/EDIT'
    },
    Role: {
        CHANGE_STATE: 'ROLE/CHANGE_STATE',
        EDIT: 'ROLE/EDIT',
        RESET: 'ROLE/RESET'
    },
    Employee: {
        CHANGE_STATE: 'EMPLOYEE/CHANGE_STATE',
        EDIT: 'EMPLOYEE/EDIT',
        RESET: 'EMPLOYEE/RESET'
    },
    PayRule: {
        CHANGE_STATE: 'PAY_RULE/CHANGE_STATE',
        EDIT: 'PAY_RULE/EDIT',
        RESET: 'PAY_RULE/RESET'
    },
    Visible: {
        CHANGE_STATE: 'VISIBLE/CHANGE_STATE',
    },
    CashRegisterPermission: {
        CHANGE_STATE: 'CASH_REGISTER_PERMISSION/CHANGE_STATE',
        EDIT: 'CASH_REGISTER_PERMISSION/EDIT',
        RESET: 'CASH_REGISTER_PERMISSION/RESET'
    },
    Branch: {
        CHANGE_STATE: 'BRANCH/CHANGE_STATE',
        EDIT: 'BRANCH/EDIT',
        RESET: 'BRANCH/RESET'
    },
    Client: {
        CHANGE_STATE: 'CLIENT/CHANGE_STATE',
        EDIT: 'CLIENT/EDIT',
        RESET: 'CLIENT/RESET',
        CHANGE_PHONE: 'CLIENT/CHANGE_PHONE',
        ADD_PHONE: 'CLIENT/ADD_PHONE'
    },
    Inventory: {
        CHANGE_STATE: 'INVENTORY/CHANGE_STATE',
        EDIT: 'INVENTORY/EDIT',
        RESET: 'INVENTORY/RESET'
    },
    Book: {
        CHANGE_STATE: 'BOOK/CHANGE_STATE',
        EDIT: 'BOOK/EDIT',
        RESET: 'BOOK/RESET',
        SELECTED: 'BOOK/SELECTED'
    },
    CashRegister: {
        CHANGE_STATE: 'CASH_REGISTER/CHANGE_STATE',
        EDIT: 'CASH_REGISTER/EDIT',
        RESET: 'CASH_REGISTER/RESET',
        SELECTED: 'CASH_REGISTER/SELECTED'
    },
    Data: {
        CHANGE_STATE: 'DATA/CHANGE_STATE'
    },
    Alert: {
        SHOW: 'ALERT/SHOW',
        CLOSE: 'ALERT/CLOSE'
    },
    Filter: {
        CHANGE_STATE: 'FILTER/CHANGE_STATE',
        EDIT: 'FILTER/EDIT',
        RESET: 'FILTER/RESET',
        RESET_TEMP: 'FILTER/RESET_TEMP',
        RESET_DATA: 'FILTER/RESET_DATA',
        SELECTED: 'FILTER/SELECTED'
    },
    NotEvent: {
        CHANGE_STATE: 'NOT_EVENT/CHANGE_STATE',
        EDIT: 'NOT_EVENT/EDIT',
        RESET: 'NOT_EVENT/RESET',
        SELECTED: 'NOT_EVENT/SELECTED'
    },
    NotTemplate: {
        CHANGE_STATE: 'NOT_TEMPLATE/CHANGE_STATE',
        EDIT: 'NOT_TEMPLATE/EDIT',
        RESET: 'NOT_TEMPLATE/RESET',
        SELECTED: 'NOT_TEMPLATE/SELECTED'
    },
    Operation: {
        CHANGE_STATE: 'OPERATION/CHANGE_STATE',
        EDIT: 'OPERATION/EDIT',
        RESET: 'OPERATION/RESET'
    },
    Order: {
        CHANGE_STATE: 'ORDER/CHANGE_STATE',
        EDIT: 'ORDER/EDIT',
        RESET: 'ORDER/RESET',
        CHANGE_FIELD: 'ORDER/CHANGE_FIELD',
        REORDER_FIELD: 'ORDER/REORDER_FIELD'
    },
    OrderPart: {
        CHANGE_STATE: 'ORDER_PART/CHANGE_STATE',
        EDIT: 'ORDER_PART/EDIT',
        RESET: 'ORDER_PART/RESET'
    },
    Payment: {
        CHANGE_STATE: 'PAYMENT/CHANGE_STATE',
        EDIT: 'PAYMENT/EDIT',
        RESET: 'PAYMENT/RESET',
        ADD_TAG: 'PAYMENT/ADD_TAG',
        DELETE_TAG: 'PAYMENT/DELETE_TAG'
    },
    Payroll: {
        CHANGE_STATE: 'PAYROLL/CHANGE_STATE',
        EDIT: 'PAYROLL/EDIT',
        RESET: 'PAYROLL/RESET'
    },
    Price: {
        CHANGE_STATE: 'PRICE/CHANGE_STATE',
        EDIT: 'PRICE/EDIT',
        RESET: 'PRICE/RESET'
    },
    Registration: {
        CHANGE_STATE: 'REGISTRATION/CHANGE_STATE',
        EDIT: 'REGISTRATION/EDIT',
        RESET: 'REGISTRATION/RESET',
        ADD_PART: 'REGISTRATION/ADD_PART',
        SAVE_PART: 'REGISTRATION/SAVE_PART',
        EDIT_PART: 'REGISTRATION/EDIT_PART',
        RESET_PART: 'REGISTRATION/RESET_PART',
        DELETE_PART: 'REGISTRATION/DELETE_PART'
    },
    Remain: {
        CHANGE_STATE: 'REMAIN/CHANGE_STATE'
    },
    SparePart: {
        CHANGE_STATE: 'SPARE_PART/CHANGE_STATE',
        EDIT: 'SPARE_PART/EDIT',
        RESET: 'SPARE_PART/RESET',
        SELECTED: 'SPARE_PART/SELECTED'
    },
    Warehouse: {
        CHANGE_STATE: 'WAREHOUSE/CHANGE_STATE',
        EDIT: 'WAREHOUSE/EDIT',
        RESET: 'WAREHOUSE/RESET',
        SELECTED: 'WAREHOUSE/SELECTED',
        EDIT_CATEGORY: 'WAREHOUSE/EDIT_CATEGORY'
    },
    Back: {
        CHANGE_STATE: 'BACK/CHANGE_STATE',
        EDIT: 'BACK/EDIT',
        RESET: 'BACK/RESET'
    },
    WarehouseMovement: {
        CHANGE_STATE: 'WAREHOUSE_MOVEMENT/CHANGE_STATE',
        EDIT: 'WAREHOUSE_MOVEMENT/EDIT',
        RESET: 'WAREHOUSE_MOVEMENT/RESET'
    },
    WriteOff: {
        CHANGE_STATE: 'WRITE_OFF/CHANGE_STATE',
        EDIT: 'WRITE_OFF/EDIT',
        RESET: 'WRITE_OFF/RESET'
    },
    WarehousePermission: {
        CHANGE_STATE: 'WAREHOUSE_PERMISSION/CHANGE_STATE',
        EDIT: 'WAREHOUSE_PERMISSION/EDIT',
        RESET: 'WAREHOUSE_PERMISSION/RESET'
    },
    DictService: {
        CHANGE_STATE: 'DICT_SERVICE/CHANGE_STATE',
        EDIT: 'DICT_SERVICE/EDIT',
        RESET: 'DICT_SERVICE/RESET',
        EDIT_GROUP: 'DICT_SERVICE/EDIT_GROUP',
        RESET_GROUP: 'DICT_SERVICE/RESET_GROUP',
    }
})