import React from 'react'
import {connect} from 'react-redux'


import {Modal} from '../../data/data'
import PartEditor from '../Wherehouse/WarehouseParts/PartEditor/PartEditor'
import RequestSparePartEditor from "../Wherehouse/RequestSpareParts/RequestSparePartEditor";
import RegistrationEditor from "../Wherehouse/WarehouseRegistration/RegistrationEditor";
import InventoryEditor from "../Wherehouse/WarehouseInventories/InventoryEditor";
import CashRegisterEditor from "../Payments/cash_registers/CashRegisterEditor";
import RoleEditor from "../Settings/SettingPages/Employees/Roles/RoleEditor";
import EmployeeEditor from "../Settings/SettingPages/Employees/EmployeeEditor";
import BranchEditor from "../Settings/SettingPages/Branches/BranchEditor";
import WarehouseEditor from "../Settings/SettingPages/Wherehouse/WarehouseEditor";
import NotEventEditor from "../Settings/SettingPages/Notification/NotEventEditor";
import NotTemplateEditor from "../Settings/SettingPages/Notification/NotTemplateEditor";
import ServiceEditor from "../Settings/SettingPages/ServicePrices/ServiceEditor";
import GroupServiceEditor from "../Settings/SettingPages/ServicePrices/GroupServiceEditor";


const RightModal = (props) => {

    const getModal = () => {
        switch (props.view.modalType) {

            case Modal.Type.PART:
                return <PartEditor/>

            case Modal.Type.REQUEST_SPARE_PART:
                return <RequestSparePartEditor/>

            case Modal.Type.REGISTRATION:
                return <RegistrationEditor/>

            case Modal.Type.INVENTORY:
                return <InventoryEditor/>

            case Modal.Type.CASH_REGISTER:
                return <CashRegisterEditor/>

            case Modal.Type.ROLE:
                return <RoleEditor/>

            case Modal.Type.EMPLOYEE:
                return <EmployeeEditor/>

            case Modal.Type.BRANCH:
                return <BranchEditor/>

            case Modal.Type.WAREHOUSE:
                return <WarehouseEditor/>

            case Modal.Type.NOT_EVENT:
                return <NotEventEditor/>

            case Modal.Type.NOT_TEMPLATE:
                return <NotTemplateEditor/>

            case Modal.Type.SERVICE:
                return <ServiceEditor/>

            case Modal.Type.SERVICE_CATEGORY:
                return <GroupServiceEditor/>
        }
    }

    if (!props.view.isRightModalOpen) return null

    return (
        <div className="modal">
            <div className='modal_right'>
                <div>{getModal()}</div>
            </div>

        </div>)

}

const mapStateToProps = state => ({
    view: state.view
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RightModal)