import {Action} from "../../data/ActionTypes";
import store from "../store";
import {serverRequest} from "./actionUtils";
import {Modal} from "../../data/data";
import {URLS} from "../../data/urls";
import {showAlert} from "../actions";
import {AlertType} from "../../data/AlertType";
import {RequestMethod} from "../../data/requestMethod";

export function changeDictServiceState(data) {
    return {
        type: Action.DictService.CHANGE_STATE,
        data
    }
}

export function editDictService(service) {
    return {
        type: Action.DictService.EDIT,
        service
    }
}

export function resetService() {
    return {
        type: Action.DictService.RESET
    }
}

function getFilter() {

    const state = store.getState()

    return {
        deleted: state.dictService.showDeleted,
        category_id: state.dictService.selected_category?.id
    }
}

export function addDictService() {

    const body = getFilter()
    const url = URLS.DictService.ADD
    const error_message = 'Запрос услуг не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.DictService.CHANGE_STATE,
                data: {services: data.data}
            })
        }
    }
}

export function createDictService() {

    const state = store.getState()

    const body = {
        title: state.dictService.title,
        price: state.dictService.price,
        cost: state.dictService.cost,
        warranty: state.dictService.warranty,
        code: state.dictService.code,
        earnings_percent: state.dictService.earnings_percent,
        earnings_summ: state.dictService.earnings_summ,
        deleted: false,
        category_id: state.dictService.category.id,
        filter: getFilter()
    }
    const url = URLS.DictService.POST
    const error_message = 'Запрос на создание услуги не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.DictService.CHANGE_STATE,
                data: {services: data.data}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.DictService.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Услуга успешно создана')
        }

    }
}

export function saveDictService() {

    const state = store.getState()

    let body = {
        id: state.dictService.edit,
        title: state.dictService.title,
        price: state.dictService.price,
        cost: state.dictService.cost,
        warranty: state.dictService.warranty,
        code: state.dictService.code,
        earnings_percent: state.dictService.earnings_percent,
        earnings_summ: state.dictService.earnings_summ,
        deleted: state.dictService.deleted,
        category_id: state.dictService.category.id,
        filter: getFilter()
    }
    const url = URLS.DictService.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение услуги не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.DictService.CHANGE_STATE,
                data: {services: data.data}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.DictService.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Услуга успешно изменена')
        }
    }
}

export function deleteDictService(flag) {

    const state = store.getState()

    let body = {
        id: state.dictService.edit,
        deleted: flag,
        filter: getFilter()
    }
    const url = URLS.DictService.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение услуги не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.DictService.CHANGE_STATE,
                data: {services: data.data}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.EMPLOYEE}
            })
            dispatch({
                type: Action.DictService.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Услуга успешно удалена/восстановлена')
        }
    }
}