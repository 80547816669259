import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeVisibleState,} from '../../../../Redux/actions'
import {changeDictServiceState} from "../../../../Redux/actions/dicrServiceActions";
import {createGroupDictService, deleteGroupDictService, resetGroupDictService, saveGroupDictService} from "../../../../Redux/actions/dictServiceGroupActions";
import {hasIDinHierarchy} from "../../../general/utils";
import {EditorID, Modal} from "../../../../data/data";

import BottomButtons from '../../../general/BottomButtons'
import LabelInput from '../../../general/LabelInput'

const GroupServiceEditor = (props) => {

    const handleClose = () => {
        props.changeVisibleState({
            isRightModalOpen: false,
            modalType: Modal.Type.NONE,
            inputDictServiceChecked: true
        })
        props.resetGroupDictService()
    }

    const clickHandel = (event) => {
        const listIds = [
            EditorID.SERVICE_CATEGORY,
        ]
        if (!hasIDinHierarchy(event, listIds)) {
            handleClose()
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    const checkConditions = () => {
        if (props.dictService.group_title) {
            return true
        } else {
            if (!props.dictService.group_title) {
                props.changeVisibleState({inputDictServiceChecked: false})
            }
        }
    }

    const handleCreate = () => {
        if (checkConditions()) {
            props.createGroupDictService()
        }
    }

    const handleSave = () => {
        if (checkConditions()) {
            props.saveGroupDictService()
        }
    }


    return (
        <div
            className="modal__box-right"
            id={EditorID.SERVICE_CATEGORY}
        >
            <h4>{props.dictService.group_edit ? props.dictService.group_title : 'Новая категория'}</h4>

            <div className='modal__body-right'>
                <LabelInput
                    className='w250 mt15'
                    title='Наименование'
                    onChange={(event) => props.changeDictServiceState({group_title: event.target.value})}
                    value={props.dictService.group_title}
                    checkedFlag='inputDictServiceChecked'
                    redStar={true}
                    disabled={props.dictService.group_deleted}
                />

            </div>


            <BottomButtons
                edit={props.dictService.group_edit}
                deleted={props.dictService.group_deleted}
                create={handleCreate}
                save={handleSave}
                delete={props.permissions.includes('setting_delete_service') ? () => props.deleteGroupDictService(true) : null}
                recover={props.permissions.includes('setting_recover_service') ? () => props.deleteGroupDictService(false) : null}
                close={handleClose}
            />
        </div>

    )
}

const mapStateToProps = (state) => ({
    dictService: state.dictService,
    inputDictServiceChecked: state.view.inputDictServiceChecked,
    permissions: state.data.user.role.permissions
})

const mapDispatchToProps = {
    changeDictServiceState,
    resetGroupDictService,
    createGroupDictService,
    changeVisibleState,
    saveGroupDictService,
    deleteGroupDictService
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupServiceEditor)
