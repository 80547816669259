import React, {useEffect, useMemo, useRef, useState} from 'react'
import Icon from "../../../general/Icon";
import {ICON} from "../../../../data/icons";

const BoxVariable = props => {

    const [listVisible, setListVisible] = useState(false)
    const elementRef = useRef()

    const clickHandel = (event) => {
        if (elementRef.current && listVisible && !elementRef.current.contains(event.target)) {
            setListVisible(false)
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })



    const mainClassName = useMemo(() => {
        let className = 'select'
        if (props.className) className += ` ${props.className}`
        if (listVisible) className += ' select_active-no-label'
        return className
    }, [props.className, listVisible])

    return (
        <div
            ref={elementRef}
            className={mainClassName}
        >
            <div
                className='input select__input'
                onClick={() => setListVisible(!listVisible)}
            >
                <div className='nowrap'>{props.title}</div>
                <Icon icon={ICON.DOWN} className={`icon icon_24 ${listVisible ? 'icon_rotate-90' : ''}`}/>
            </div>
            {listVisible ?
                <div className='select__drop-list'>
                    <div className='select__drop-list-body1'>
                        {props.list_var.map((variable, idx) => (
                            <div
                                key={idx}
                                className='select__item select__item_option'
                                onClick={() => props.func(variable)}
                            >
                                {variable}
                            </div>
                        ))}
                    </div>
                </div>
                : null
            }
        </div>
    )
}


export default BoxVariable