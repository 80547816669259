import React from 'react'
import { connect } from 'react-redux'

import { changeNotTemplateState} from '../../../../Redux/actions/notTemplateAction'
import {changeVisibleState} from '../../../../Redux/actions'

import Button from '../../../general/Button'
import TemplateTable from './TemplateTable'
import Checkbox from '../../../general/Checkbox'
import {ButtonData} from "../../../../data/ButtonData";
import {CheckboxDate} from "../../../../data/checkboxData";
import {Permissions} from "../../../../data/permissions";
import {Modal} from "../../../../data/data";

const NotificationTemplate = props => {

    return (
        <div className = 'box__forms'>
            <h4>Шаблоны</h4>
            <p>Создайте шаблон для оповещений или напоминаний.</p>
            <div className='two-buttons ai-c'>
                <Button
                    size={ButtonData.Size.MEDIUM}
                    type={ButtonData.Type.CREATE}
                    title='Шаблон'
                    onClick={() => props.changeVisibleState({isRightModalOpen: true, modalType: Modal.Type.NOT_TEMPLATE})}
                    invisible={!props.permissions.includes(Permissions.CAN_CREATE_NOTIFICATION_TEMPLATE)}
                />
                <Checkbox
                    type={CheckboxDate.Type.SLIDE_THREE}
                    label='Показать удаленные'
                    onChange={event => props.changeNotTemplateState({showDeleted: event.target.checked})}
                    checked={props.showDeleted}
                    invisible={!props.permissions.includes(Permissions.CAN_SEE_DELETED_NOTIFICATION_TEMPLATE)}
                />
            </div>
            <TemplateTable/>
        </div>
    )
}

const mapStateToProps = state => ({
    showDeleted: state.notTemplate.showDeleted,
    permissions: state.data.user.role.permissions
})

const mapDispatchToProps = {
    changeVisibleState,
    changeNotTemplateState
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTemplate)