import store from '../store'
import {serverRequest} from './actionUtils'
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {Modal} from "../../data/data";
import {showAlert} from "../actions";
import {AlertType} from "../../data/AlertType";
import {RequestMethod} from "../../data/requestMethod";


export function changeNotEventState( data ) {
    return {
        type: Action.NotEvent.CHANGE_STATE,
        data
    }
}

export function resetNotEvent() {
    return {
        type: Action.NotEvent.RESET
    }
}


export function selectedNotEvent( value, field, saveToApp=false ) {
    return {
        type: Action.NotEvent.SELECTED,
        field,
        value,
        saveToApp
    }
}

function getFilter() {

    const state = store.getState()

    return {
        deleted: state.notEvent.showDeleted,
        target_audience_id: state.notEvent.filter_target_audience || null
    }
}

export function getNotEvent(event_id){

    const body = {id: event_id}
    const url = URLS.NotEvent.GET
    const error_message = 'Запрос уведомления не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.NotEvent.EDIT,
                notificationEvent: data.notification_event,
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: true, modalType: Modal.Type.NOT_EVENT},
            })
        }

    }
}

export function addNotEvent() {

    const body = getFilter()
    const url = URLS.NotEvent.ADD
    const error_message = 'Запрос событий не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.NotEvent.CHANGE_STATE,
                data: {events: data.notification_events}
            })
        }
    }
}

export function createNotEvent() {

    const state = store.getState()

    const body = {
        event_type_id: state.notEvent.event_type.id,
        target_audience_id: state.notEvent.target_audience_id,
        notification_type_id: state.notEvent.notification_type.id,
        statuses: state.notEvent.statuses.map(status => status.id),
        notification_template_id: state.notEvent.notification_template.id,
        filter: getFilter()
    }
    const url = URLS.NotEvent.POST
    const error_message = 'Запрос на создание события не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

            if (data.success) {
                dispatch({
                    type: Action.NotEvent.CHANGE_STATE,
                    data: {events: data.notification_events}
                })
                dispatch({
                    type: Action.Visible.CHANGE_STATE,
                    data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
                })
                dispatch({
                    type: Action.NotEvent.RESET
                })
                showAlert(dispatch, AlertType.SUCCESSES, 'Событие успешно создано')
            }
    }
}

export function saveNotEvent() {

    const state = store.getState()

    const body = {
        id: state.notEvent.edit,
        event_type_id: state.notEvent.event_type.id,
        target_audience_id: state.notEvent.target_audience_id,
        notification_type_id: state.notEvent.notification_type.id,
        statuses: state.notEvent.statuses.map(status => status.id),
        notification_template_id: state.notEvent.notification_template.id,
        filter: getFilter()
    }
    const url = URLS.NotEvent.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение события не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

            if (data.success) {
                dispatch({
                    type: Action.NotEvent.CHANGE_STATE,
                    data: {events: data.notification_events}
                })
                dispatch({
                    type: Action.Visible.CHANGE_STATE,
                    data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
                })
                dispatch({
                    type: Action.NotEvent.RESET
                })
                showAlert(dispatch, AlertType.SUCCESSES, 'Событие успешно изменено')
            }
    }
}

export function deleteNotEvent(flag) {

    const state = store.getState()

    const body = {
        id: state.notEvent.edit,
        deleted: flag,
        filter: getFilter()
    }
    const url = URLS.NotEvent.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановление события не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.NotEvent.CHANGE_STATE,
                data: {events: data.notification_events}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.NotEvent.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Событие успешно удалено/восстановлено')
        }
    }
}