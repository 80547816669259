import React, {useEffect} from 'react'
import {connect} from "react-redux"

import {changeVisibleState} from "../../../../Redux/actions"
import {addDictService, changeDictServiceState} from "../../../../Redux/actions/dicrServiceActions";
import {editGroupDictService} from "../../../../Redux/actions/dictServiceGroupActions";
import {EditorID, Modal} from "../../../../data/data"
import {ButtonData} from "../../../../data/ButtonData"

import Button from "../../../general/Button"



const ServiceGroupTable = (props) => {

    useEffect(() => {
        props.addDictService()
    }, [props.dictService.selected_category, props.showDeleted])

    const groups = props.group_dict_service.filter(group => props.showDeleted | !group.deleted)
    const count_group = groups.length

    const editGroup = (group) => {
        if (props.permissions.includes('setting_edit_service')) {
            props.editGroupDictService(group)
            props.changeVisibleState({
                isRightModalOpen: true,
                modalType: Modal.Type.SERVICE_CATEGORY
            })
        }
    }

    return (
        <div className='w350 pt10'>
            <Button
                id={EditorID.NEW_PRICE_CATEGORY}
                title='Категория'
                size={ButtonData.Size.MEDIUM}
                type={ButtonData.Type.CREATE}
                onClick={() => props.changeVisibleState({isRightModalOpen: true, modalType: Modal.Type.SERVICE_CATEGORY})}
                invisible={!props.permissions.includes('setting_create_service')}
            />
            <table className='mt15'>
                <thead>
                <tr>
                    <th className='th'>Категория</th>
                    <th className='th w50'>Кол-во</th>
                </tr>
                </thead>
                <tbody>
                {groups.map(group => (
                    <tr
                        key={group.id}
                        className={group.deleted ? 'tr_deleted' : 'tr'}
                        onClick={() => props.changeDictServiceState({selected_category: group})}
                        onDoubleClick={() => editGroup(group)}
                        style={group.id === props.dictService.selected_category?.id ? {backgroundColor: '#272b3a'} : null}
                    >
                        <td className='td pd5'>{group.title}</td>
                        <td className='td pd5'>{group.count}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className='mt15'>Всего - {count_group}</div>
        </div>
    )
}

const mapStateToProps = state => ({
    dictService: state.dictService,
    group_dict_service: state.dictService.serviceGroups,
    permissions: state.data.user.role.permissions,
    showDeleted: state.dictService.showDeleted
})

const mapDispatchToProps = {
    editGroupDictService,
    changeVisibleState,
    changeDictServiceState,
    addDictService,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceGroupTable)