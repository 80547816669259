import store from "../store";
import {bad_request, getRequestConfig, serverRequest} from "./actionUtils";
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {Modal} from "../../data/data";
import {showAlert} from "../actions";
import {AlertType} from "../../data/AlertType";
import {RequestMethod} from "../../data/requestMethod";

export function editGroupDictService(group) {
    return {
        type: Action.DictService.EDIT_GROUP,
        group
    }
}


export function resetGroupDictService() {
    return {
        type: Action.DictService.RESET_GROUP
    }
}

function getFilter() {

    const state = store.getState()

    return {
        deleted: state.dictService.showDeleted
    }
}

export function addGroupService() {

    const body = getFilter()
    const url = URLS.DictServiceGroup.ADD
    const error_message = 'Запрос категорий услуг не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.DictService.CHANGE_STATE,
                data: {serviceGroups: data.data},
            })
        }
    }
}

export function createGroupDictService() {

    const state = store.getState()

    const body = {
        title: state.dictService.group_title,
        deleted: state.dictService.group_deleted,
        filter: getFilter()
    }
    const url = URLS.DictServiceGroup.POST
    const error_message = 'Запрос на создание категории услуг не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.DictService.CHANGE_STATE,
                data: {serviceGroups: data.data}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.DictService.RESET_GROUP
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Категория услуг успешно создана')
        }
    }
}

export function saveGroupDictService() {

    const state = store.getState()

    let body = {
        id: state.dictService.group_edit,
        title: state.dictService.group_title,
        deleted: state.dictService.group_deleted,
        filter: getFilter()
    }
    const url = URLS.DictServiceGroup.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение категории услуг не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.DictService.CHANGE_STATE,
                data: {serviceGroups: data.data}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.DictService.RESET_GROUP
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Категория услуг успешно изменена')
        }
    }
}

export function deleteGroupDictService(flag) {

    const state = store.getState()

    let body = {
        id: state.dictService.group_edit,
        deleted: flag,
        filter: getFilter()
    }
    const url = URLS.DictServiceGroup.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановление категории услуг не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.DictService.CHANGE_STATE,
                data: {serviceGroups: data.data}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.DictService.RESET_GROUP
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Категория услуг успешно удалена/восстановлена')
        }
    }
}