import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { changeVisibleState } from '../../../../Redux/actions'
import {createNotTemplate, resetNotTemplate} from '../../../../Redux/actions/notTemplateAction'
import {saveNotTemplate, deleteNotTemplate, changeNotTemplateState} from '../../../../Redux/actions/notTemplateAction'
import {hasIDinHierarchy} from "../../../general/utils"
import {listVarAnother, listVarClient, listVarCompany, listVarDate} from '../../../../data/varNames'
import {listVarEmployee, listVarFinance, listVarOrder} from '../../../../data/varNames'
import {EditorID, Modal} from "../../../../data/data"
import {Permissions} from "../../../../data/permissions";

import BottomButtons from '../../../general/BottomButtons'
import BoxVariable from './BoxVariable'
import LabelInput from '../../../general/LabelInput'
import LabelArea from '../../../general/LableArea'

const NotTemplateEditor = props => {

    const handleClose = () => {
        props.changeVisibleState({
            isRightModalOpen: false,
            modalType: Modal.Type.NONE,
            inputNotTempTitleChecked: true,
            inputNotTempTempleChecked: true
        })
        props.resetNotTemplate()
    }

    const clickHandel = (event) => {
        const listIDs = [EditorID.NOT_TEMPLATE, EditorID.NEW_TEMPLATE]

        if (!hasIDinHierarchy(event, listIDs)) {
            handleClose()
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    const checkAllConditions = () => {

        if (props.notTemplate.title && props.notTemplate.template) {
            return true
        }
        if (!props.notTemplate.title) {
            props.changeVisibleState({inputNotTempTitleChecked: false})
        }
        if (!props.notTemplate.template) {
            props.changeVisibleState({inputNotTempTempleChecked: false})
        }

        return false
    }

    const handleCreate = () => {
        if (checkAllConditions()) {
            props.createNotTemplate()
        }
    }

    const handleSave = () => {
        if (checkAllConditions()) {
            props.saveNotTemplate()
        }
    }

    const can_deleted = props.permissions.includes(Permissions.CAN_DELETE_NOTIFICATION_TEMPLATE)
    const can_recover = props.permissions.includes(Permissions.CAN_RECOVER_NOTIFICATION_TEMPLATE)

    return (
        <div className="modal__box-right modal__box-right_w300" id={EditorID.NOT_TEMPLATE}>
                <h4>{props.notTemplate.edit ? props.notTemplate.title : 'Новый Шаблон'}</h4>

                <div className="modal__body-right">
                    <div className='modal__block-forms w250'>
                        <LabelInput
                            title="Название"
                            onChange={event => props.changeNotTemplateState({title: event.target.value})}
                            value={props.notTemplate.title}
                            checkedFlag="inputNotTempTitleChecked"
                            redStar={ true }
                            disabled={props.notTemplate.deleted}
                        />
                        <LabelArea
                            title='Текст'
                            onChange={event => props.changeNotTemplateState({template: event.target.value})}
                            value={props.notTemplate.template}
                            checkedFlag='inputNotTempTempleChecked'
                            redStar={true}
                            disabled={props.notTemplate.deleted}
                        />
                        <h3>Переменные</h3>
                        <BoxVariable
                            title='Компания'
                            list_var={listVarCompany}
                            func={vr => props.changeNotTemplateState({template: props.notTemplate.template + vr})}
                        />
                        <BoxVariable
                            title='Клиент'
                            list_var={listVarClient}
                            func={vr => props.changeNotTemplateState({template: props.notTemplate.template + vr})}
                        />
                        <BoxVariable
                            title='Заказ'
                            list_var={listVarOrder}
                            func={vr => props.changeNotTemplateState({template: props.notTemplate.template + vr})}
                        />
                        <BoxVariable
                            title='Сотрудник'
                            list_var={listVarEmployee}
                            func={vr => props.changeNotTemplateState({template: props.notTemplate.template + vr})}
                        />
                        <BoxVariable
                            title='Финансы'
                            list_var={listVarFinance}
                            func={vr => props.changeNotTemplateState({template: props.notTemplate.template + vr})}
                        />
                        <BoxVariable
                            title='Дата'
                            list_var={listVarDate}
                            func={vr => props.changeNotTemplateState({template: props.notTemplate.template + vr})}
                        />
                        <BoxVariable
                            title='Прочие'
                            list_var={listVarAnother}
                            func={vr => props.changeNotTemplateState({template: props.notTemplate.template + vr})}
                        />
                    </div>
                </div>


                <BottomButtons
                    edit={props.notTemplate.edit}
                    deleted={props.notTemplate.deleted}
                    create={handleCreate}
                    save={handleSave}
                    delete={can_deleted ? () => props.deleteNotTemplate(true) : null}
                    recover={can_recover ? () => props.deleteNotTemplate(false) : null}
                    close={handleClose}
                />
            </div>
    )
}

const mapStateToProps = state => ({
    notTemplate: state.notTemplate,
    permissions: state.data.user.role.permissions
})

const mapDispatchToProps = {
    changeVisibleState,
    changeNotTemplateState,
    resetNotTemplate,
    createNotTemplate,
    saveNotTemplate,
    deleteNotTemplate
}

export default connect(mapStateToProps, mapDispatchToProps)(NotTemplateEditor)
