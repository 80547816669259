import React, {useMemo} from "react";

import Button from "../../../general/Button";
import TableFields from "../../../general/TableFields";
import {Table} from "../../../../data/tableHeaders";
import ServicePrice from "./ServicePrice";
import {connect} from "react-redux";
import {EditorID, Modal} from "../../../../data/data";
import {ButtonData} from "../../../../data/ButtonData";
import {changeVisibleState} from "../../../../Redux/actions";
import {includesObject} from "../../../general/utils";
import TableHeader from "../../../general/TableHeader";
import {changeDictServiceState, editDictService} from "../../../../Redux/actions/dicrServiceActions";

const ServiceTable = (props) => {

    const services = props.dict_service.filter(service => props.showDeleted | !service.deleted)
    const count_services = services.length

    const tableFields = useMemo(() =>
            Table.Fields.Service.filter(header => includesObject(header, props.dictService.table_headers)),
        [props.dictService.table_headers]
    );

    const tableMarginFields = useMemo(() =>
            props.discount_margin
                .filter(margin => margin.margin_type === 1)
                .map((margin, idx) => ({
                    id: idx + 6,
                    margin_id: margin.id,
                    title: margin.title,
                    field: `margin_${margin.id}`,
                    width: 70,
                    visible: true,
                    order: idx + 6
                })),
        [props.discount_margin]
    );


    const editService = (service) => {
        if (props.permissions.includes('setting_edit_service')) {
            props.editDictService(service)
            props.changeVisibleState({
                isRightModalOpen: true,
                modalType: Modal.Type.SERVICE
            })
        }
    }

    return (
        <div className='box'>
            <div className='page-buttons'>
                <Button
                    id={EditorID.NEW_SERVICE}
                    size={ButtonData.Size.MEDIUM}
                    type={ButtonData.Type.CREATE}
                    title='Услуга'
                    onClick={() => props.changeVisibleState({
                        isRightModalOpen: true,
                        modalType: Modal.Type.SERVICE
                    })}
                    invisible={!props.permissions.includes('setting_create_service')}
                />
                <TableFields
                    id='service'
                    list={Table.Fields.Service.concat(tableMarginFields)}
                    checked_list={props.dictService.table_headers}
                    func={table_headers => props.changeDictServiceState({table_headers: table_headers})}
                />
            </div>
            <table className='mt15'>
                <thead>
                <tr>
                    {tableFields.map(header => (
                        <TableHeader
                            key={header.id}
                            header={header}
                            changeState={props.changeDictServiceState}
                            headers={props.dictService.table_headers}
                        />
                    ))}
                    {tableMarginFields.map(header => (
                        <TableHeader
                            key={header.id}
                            header={header}
                            changeState={props.changeMovementState}
                            headers={props.dictService.table_headers}
                        />
                    ))}
                </tr>
                </thead>
                <tbody>
                {services.map(service => (
                    <tr
                        key={service.id}
                        className={service.deleted ? 'tr_deleted' : 'tr'}
                    >
                        {tableFields.filter(header => header.visible).map(header => (
                            <td
                                className='td pd5'
                                key={header.id}
                                onDoubleClick={() => editService(service)}
                            >
                                {service[header.field]}
                            </td>
                        ))}
                        {tableMarginFields.filter(header => header.visible).map(header => (
                            <ServicePrice
                                key={header.id}
                                margin_id={header.margin_id}
                                service_id={service.id}
                                disabled={!props.permissions.includes('setting_edit_service')}
                            />
                        ))}
                    </tr>
                ))}

                </tbody>
            </table>
            <div className='mt15'>Всего - {count_services}</div>
        </div>
    )
}

const mapStateToProps = state => ({
    permissions: state.data.user.role.permissions,
    dict_service: state.dictService.services,
    discount_margin: state.price.discount_margin,
    dictService: state.dictService,
    showDeleted: state.dictService.showDeleted
})

const mapDispatchToProps = {
    changeVisibleState,
    editDictService,
    changeDictServiceState
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTable)