import {Action} from "../../data/ActionTypes";

const initialState = {

    templates: [],

    edit: 0,
    title: '',
    template: '',
    deleted: false,

    showDeleted: false
}

export const notTemplateReducer = (state = initialState, action) => {
    switch (action.type){

        case Action.NotTemplate.CHANGE_STATE: {
            return {...Object.assign(state, action.data)}
        }

        case Action.NotTemplate.EDIT: {
            return {
                ...state,
                edit: action.template.id,
                title: action.template.title,
                template: action.template.template,
                deleted: action.template.deleted
            }
        }

        case Action.NotTemplate.RESET: {
            return {
                ...state,
                edit: 0,
                title: '',
                template: '',
                deleted: false,
            }
        }

        default: return state
    }

}