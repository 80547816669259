import {createResidueRule} from "../Redux/actions/partAction";

export const URLS = Object.freeze({
    MainData: {
        GET: '/get_main_data'
    },
    Inventory: {
        GET: '/get_warehouse_inventory',
        ADD:  '/get_warehouse_inventories',
        POST: '/warehouse_inventory'
    },
    Book: {
        TYPE_POST: '/equipment_type',
        TYPE_ADD: '/get_equipment_type',
        BRAND_POST: '/equipment_brand',
        BRAND_ADD: '/get_equipment_brand',
        SUBTYPE_POST: '/equipment_subtype',
        SUBTYPE_ADD: '/get_equipment_subtype',
        MODEL_POST: '/equipment_model',
        MODEL_ADD: '/get_equipment_model',
    },
    Branch: {
        GET: '/get_branch',
        ADD: '/get_branches',
        POST: '/branch'
    },
    CashRegister: {
        ADD: '/get_cashbox',
        POST: '/cashbox'
    },
    Employee: {
        GET: '/get_employee',
        ADD: '/get_employees',
        POST: '/employee',
        CHANGE_AVATAR: '/change_avatar',
        LOGOUT: '/logout',
        CHANGE_PASSWORD: '/change_user_password'
    },
    Filter: {
        ADD: '/get_custom_filters',
        POST: '/custom_filters'
    },
    General_info: {
        POST: '/generally_info'
    },
    NotEvent: {
        GET: '/get_notification_event',
        ADD: '/get_notification_events',
        POST: '/notification_events'
    },
    NotTemplate: {
        GET: '/get_notification_template',
        ADD: '/get_notification_templates',
        POST: '/notification_template'
    },
    Operation: {
        POST: '/operations'
    },
    Order: {
        GET: '/get_order',
        ADD: '/get_orders',
        POST: '/orders',
        CHANGE_STATUS: '/change_order_status',
        COMMENT: '/order_comment'
    },
    OrderPart: {
        POST: '/order_parts'
    },
    Part: {
        GET: '/get_part',
        ADD: '/get_parts',
        POST: '/parts'
    },
    ResidueRule: {
        POST: '/residue_rule'
    },
    Payment: {
        ADD: '/get_payments',
        POST: '/payments'
    },
    Payroll: {
        GET_SUM: '/get_payroll_sum',
        ADD: '/get_payrolls',
        POST: '/payroll'
    },
    PayRule: {
        POST: '/pay_rule'
    },
    Price: {
        ADD: '/get_service_prices',
        POST: '/service_prices'
    },
    DiscountMargin: {
        ADD: '/get_discount_margin',
        POST: '/discount_margin'
    },
    Registration: {
        GET: '/get_warehouse_registration',
        ADD: '/get_warehouse_registrations',
        POST: '/warehouse_registrations'
    },
    Remain: {
        GET_REMAIN: '/get_warehouse_remains',
        GET_BATCHES: '/get_batches'
    },
    SparePart: {
        GET: '/get_request_spare_part',
        ADD: '/get_request_spare_parts',
        POST: '/request_spare_parts',
        COMMENT: '/request_spare_part_comment',
        CHANGE_STATUS: '/change_request_spare_part_status'
    },
    Role: {
        GET: '/get_role',
        ADD: '/get_roles',
        POST: '/role'
    },
    Warehouse: {
        GET: '/get_warehouse',
        ADD: '/get_warehouses',
        POST: '/warehouse'
    },
    WarehouseCategory: {
        ADD: '/get_warehouse_category',
        POST: '/warehouse_category'
    },
    Back: {
        GET: '/get_warehouse_back',
        ADD: '/get_warehouse_backs',
        POST: '/warehouse_backs'
    },
    WarehouseMovement: {
        GET: '/get_warehouse_movement',
        ADD: '/get_warehouse_movements',
        POST: '/warehouse_movement'
    },
    WriteOff: {
        GET: '/get_warehouse_write_of',
        ADD: '/get_warehouse_write_offs',
        POST: '/warehouse_write_of'
    },
    DictService: {
        GET: '/get_dict_service',
        ADD: '/get_dict_services',
        POST: '/dict_service'
    },
    DictServiceGroup: {
        GET: '/get_group_dict_service',
        ADD: '/get_group_dict_services',
        POST: '/group_dict_service'
    }
})