import React from 'react'
import { connect } from 'react-redux'

import { changeVisibleState } from '../../../../Redux/actions'
import {editNotTemplate} from '../../../../Redux/actions/notTemplateAction'
import {Modal} from "../../../../data/data";
import {Permissions} from "../../../../data/permissions";

import Data from "../../../general/cell/Data";

const TemplateTable = props => {

    const handleEdit = (template) => {
        if(props.permissions.includes(Permissions.CAN_EDIT_NOTIFICATION_TEMPLATE)) {
            props.editNotTemplate(template)
            props.changeVisibleState({isRightModalOpen: true, modalType: Modal.Type.NOT_TEMPLATE})
        }
    }

    return (
        <table>
            <thead>
            <tr>
                <th className='th th_w150'>Название</th>
                <th className='th'>Шаблон</th>
            </tr>
            </thead>
            <tbody>
            {props.notTemplate.templates.map(template => {
                return (
                    <tr
                        key={template.id}
                        className={template.deleted ? 'tr_deleted' : 'tr'}
                        onDoubleClick={() =>handleEdit(template)}
                    >
                        <Data data={template.title}/>
                        <Data data={template.template}/>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

const mapStateToProps = state => ({
    notTemplate: state.notTemplate,
    permissions: state.data.user.role.permissions
})

const mapDispatchToProps = {
    changeVisibleState,
    editNotTemplate
}


export default connect (mapStateToProps, mapDispatchToProps) (TemplateTable)