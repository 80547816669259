import store from '../store'
import {Action} from "../../data/ActionTypes";
import {serverRequest} from './actionUtils'
import {URLS} from "../../data/urls";
import {Modal} from "../../data/data";
import {RequestMethod} from "../../data/requestMethod";


export function changeNotTemplateState( data ) {
    return {
        type: Action.NotTemplate.CHANGE_STATE,
        data
    }
}

export function editNotTemplate(template) {
    return {
        type: Action.NotTemplate.EDIT,
        template
    }
}

export function resetNotTemplate() {
    return {
        type: Action.NotTemplate.RESET
    }
}

function getFilter() {

    const state = store.getState()

    return {
        deleted: state.notTemplate.showDeleted
    }
}

export function getNotTemplate(template_id){

    const body = {id: template_id}
    const url = URLS.NotTemplate.GET
    const error_message = 'Запрос шаблона не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.NotTemplate.EDIT,
                template: data.template,
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: true, modalType: Modal.Type.NOT_TEMPLATE},
            })
        }

    }
}

export function addNotTemplate() {

    const body = getFilter()
    const url = URLS.NotTemplate.ADD
    const error_message = 'Запрос шаблонов не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.NotTemplate.CHANGE_STATE,
                data: {templates: data.templates}
            })

        }
    }
}

export function createNotTemplate() {

    const state = store.getState()

    const body = {
        title: state.notTemplate.title,
        template: state.notTemplate.template,
        deleted: false,
        filter: getFilter()
    }
    const url = URLS.NotTemplate.POST
    const error_message = 'Запрос на создание шаблона не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.NotTemplate.CHANGE_STATE,
                data: {templates: data.templates}
            })
            dispatch({
                type: Action.NotTemplate.RESET,
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE},
            })
        }
    }
}

export function saveNotTemplate() {

    const state = store.getState()

    const body = {
        id: state.notTemplate.edit,
        title: state.notTemplate.title,
        template: state.notTemplate.template,
        deleted: false,
        filter: getFilter()
    }
    const url = URLS.NotTemplate.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение шаблона не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.NotTemplate.CHANGE_STATE,
                data: {templates: data.templates}
            })
            dispatch({
                type: Action.NotTemplate.RESET,
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE},
            })
        }
    }
}

export function deleteNotTemplate(flag) {

    const state = store.getState()

    let body = {
        id: state.notTemplate.edit,
        deleted: flag,
        filter: getFilter()
    }
    const url = URLS.NotTemplate.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановление шаблона не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.NotTemplate.CHANGE_STATE,
                data: {templates: data.templates}
            })
            dispatch({
                type: Action.NotTemplate.RESET,
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE},
            })
        }
    }
}