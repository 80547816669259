import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeVisibleState} from '../../../../Redux/actions'
import LabelInput from '../../../general/LabelInput'
import BottomButtons from '../../../general/BottomButtons'
import ChooseButton from '../../../general/ChooseButton'
import WarningOrange from '../../../general/WarningOrange'
import {
    changeDictServiceState,
    createDictService,
    deleteDictService,
    resetService,
    saveDictService
} from "../../../../Redux/actions/dicrServiceActions";
import {EditorID, Modal} from "../../../../data/data";
import {checkObject, hasIDinHierarchy} from "../../../general/utils";
import SelectFromList from "../../../general/SelectFromList";

const ServiceEditor = (props) => {

    const handleClose = () => {
        props.changeVisibleState({
            isRightModalOpen: false,
            modalType: Modal.Type.NONE,
            inputServiceTitleChecked: true,
            inputServiceCategoryIdChecked: true,
            inputServicePriceChecked: true
        })
        props.resetService()
    }

    const clickHandel = (event) => {
        const listIds = [
            EditorID.SERVICE,
        ]
        if (!hasIDinHierarchy(event, listIds)) {
            handleClose()
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    const checkCreateConditions = () => {
        if (
            props.dictService.title &&
            checkObject(props.dictService.category) &&
            props.dictService.price
        ) {
            return true
        } else {
            if (!props.dictService.title) {
                props.changeVisibleState({inputServiceTitleChecked: false})
            }
            if (!checkObject(props.dictService.category)) {
                props.changeVisibleState({inputServiceCategoryIdChecked: false})
            }
            if (!props.dictService.price) {
                props.changeVisibleState({inputServicePriceChecked: false})
            }
            return false
        }
    }

    const handleCreate = () => {
        if (checkCreateConditions()) {
            props.createDictService()
        }
    }

    const handleSave = () => {
        if (checkCreateConditions()) {
            props.saveDictService()
        }
    }

    return (
        <div className="modal__box-right"
             id={EditorID.SERVICE}
        >
            <h4>{props.dictService.edit ? props.dictService.title : 'Новая услуга'}</h4>

            <div className="modal__body-right">
                <div className='modal__block-forms w250'>
                    <LabelInput
                        title='Наименование'
                        onChange={(event) => props.changeDictServiceState({title: event.target.value})}
                        value={props.dictService.title}
                        checkedFlag='inputServiceTitleChecked'
                        redStar={true}
                        disabled={props.dictService.deleted}
                    />
                    <SelectFromList
                        title='Категория'
                        list={props.group_dict_service}
                        setElement={category => props.changeDictServiceState({category})}
                        current_object={props.dictService.category}
                        checkedFlag='inputServiceCategoryIdChecked'
                        noChoosed='Выберете категорию'
                        disabled={props.dictService.deleted}
                    />
                    <LabelInput
                        title='Цена'
                        onChange={event => props.changeDictServiceState({price: event.target.value.replace(/[^0-9.]/g, '')})}
                        value={props.dictService.price}
                        unit='руб.'
                        checkedFlag='inputServicePriceChecked'
                        redStar={true}
                        disabled={props.dictService.deleted}
                    />
                    <LabelInput
                        title='Себестоимость'
                        onChange={event => props.changeDictServiceState({cost: event.target.value.replace(/[^0-9.]/g, '')})}
                        value={props.dictService.cost}
                        unit='руб.'
                        disabled={props.dictService.deleted}
                    />
                    <div className='two-buttons'>
                        <LabelInput
                            className={'w130'}
                            title='Гарантия'
                            onChange={event => props.changeDictServiceState({warranty: event.target.value.replace(/[^0-9]/g, '') * props.dictService.warranty_value})}
                            value={parseInt(props.dictService.warranty / props.dictService.warranty_value)}
                            disabled={props.dictService.deleted}
                        />
                        <ChooseButton
                            className='ml30'
                            name={['Дни', 'Мес']}
                            func1={() => props.changeDictServiceState({warranty_value: 24 * 60 * 60})}
                            func2={() => props.changeDictServiceState({warranty_value: 30 * 24 * 60 * 60})}
                            disabled={props.dictService.deleted}
                        />
                    </div>
                    <LabelInput
                        title='Штрихкод'
                        onChange={event => props.changeDictServiceState({code: event.target.value})}
                        value={props.dictService.code}
                        disabled={props.dictService.deleted}
                    />
                </div>
                <div className='modal__block-forms'>
                    <h4>Вознаграждение исполнителю</h4>
                    <WarningOrange
                        text='Заполните эти поля, если хотите, чтобы за эту услугу исполнитель получал нестандартное вознаграждение. В противном случае оставьте эти поля пустыми, и при расчете ЗП будут учитываться общие правила расчета вознаграждения, заданные в карточке исполнителя'
                    />
                </div>
                <div className='modal__block-forms w250'>
                    <LabelInput
                        title='Процент'
                        onChange={event => props.changeDictServiceState({earnings_percent: event.target.value.replace(/[^0-9.]/g, '')})}
                        value={props.dictService.earnings_percent}
                        unit='%'
                        disabled={props.dictService.deleted}
                    />
                    <LabelInput
                        width='70px'
                        title='Сумма'
                        onChange={event => props.changeDictServiceState({earnings_summ: event.target.value.replace(/[^0-9.]/g, '')})}
                        value={props.dictService.earnings_summ}
                        unit='руб'
                        disabled={props.dictService.deleted}
                    />
                </div>
            </div>


            <BottomButtons
                edit={props.dictService.edit}
                deleted={props.dictService.deleted}
                create={handleCreate}
                save={handleSave}
                delete={props.permissions.includes('setting_delete_service') ? () => props.deleteDictService(true) : null}
                recover={props.permissions.includes('setting_recover_service') ? () => props.deleteDictService(false) : null}
                close={handleClose}
            />
        </div>

    )
}

const mapStateToProps = (state) => ({
    dictService: state.dictService,
    view: state.view,
    group_dict_service: state.dictService.serviceGroups,
    permissions: state.data.user.role.permissions
})

const mapDispatchToProps = {
    changeDictServiceState,
    changeVisibleState,
    resetService,
    createDictService,
    saveDictService,
    deleteDictService
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceEditor)
